@use "assets/styles/global" as *;

$list-gutter: 32px;

.list {
  margin: 30px $gutter;
}

.list-header,
.list-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  p:last-child {
    text-align: right;
  }

  @include sm-md {
    grid-template-columns: 1fr;

    p {
      display: none;
    }

    p:first-child {
      display: block;
    }
  }
}

.list-header {
  margin: 0 $list-gutter 11px;

  p {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.list-item {
  padding: 20px $list-gutter;
  background-color: white;
  box-shadow: 0px 4px 12px #1616590a;
  border: 2px solid #edeff0;
  border-radius: 6px;
  cursor: pointer;

  p:nth-child(1) {
    font-size: 18px;
    font-weight: 600;
  }
}

.button.create-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
}
