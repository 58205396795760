@use "assets/styles/global" as *;

.edit {
  background-color: white;
  box-shadow: 0px 3px 6px #b7bcc753;
  border-radius: 8px;
  margin: 11px 5px;
  padding: 18px $gutter;

  .loading-text {
    text-align: center;
  }
}

.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button.button {
    padding: 0;
  }

  .arrow-icon {
    height: 18px;
  }
  .close-icon {
    height: 26px;
    width: 26px;
  }
}

.edit-body {
  max-width: 1200px;
  margin: 1rem auto;
}

.edit-body-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 24px;
    margin: 3rem 0 2rem;
  }
}
 
.edit-footer {
  display: flex;
  padding-top: 5rem;
  padding-bottom: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.setup-code-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
  }
}