@use "assets/styles/global" as *;

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @include sm-md {
    grid-template-columns: 1fr;
  }
}

.input {
  background-color: #fff;
  color: #000;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 16px;

  &:focus {
    border: none;
    outline: 2px solid #3c3c3c;
  }

  &:disabled,
  &.disabled {
    background-color: #f4f4f4;
    opacity: 0.5;
  }

  &.error-input {
    outline: 2px solid $danger-color;
  }
}

.with-label {
  display: flex;
  flex-direction: column;

  .label {
    font-weight: 500;
    margin-bottom: 5px;
  }
}
