@use "assets/styles/global" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 5px;
  padding: 20px $gutter;
  background-color: #fafbfc;
  border-radius: 8px;
  box-shadow: 0px 4px 12px #42467e0a;
}
