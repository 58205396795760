@use "assets/styles/global" as *;

.outdated-settings-section {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;

  svg {
    max-height: 18px;
    max-width: 18px;
  }
}

.outdated-settings-text {
    display: inline-block;
}

.info-hover-text {
  display: none;
}
