@use "assets/styles/global" as *;

.button {
  border: none;
  font-family: $main-font;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  &.theme-primary {
    background-color: $primary-color;
    color: #fff;
  }

  &.theme-secondary {
    border: 3px solid #cbcbcb;
    background-color: #f8f8f8;
    border-radius: 14px;
    color: #434343;
    box-shadow: 0 8px 20px #0000000f;
  }

  &.theme-danger {
    background-color: #ffebeb;
    color: $danger-color;
  }

  &.theme-text {
    background-color: transparent;
    color: $text-color;
  }

  &.size-md {
    font-size: 16px;
    height: 47px;
    padding: 0 20px;
    border-radius: 8px;
  }

  &.size-lg {
    font-size: 40px;
    height: 115px;
    padding: 0 110px;
    border-radius: 19px;
  }
}
