@use "assets/styles/global" as *;

$spinner-background: rgba(48, 60, 55, 0.15);
$spinner-color: rgba(48, 60, 55, 0.8);

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: spin 0.6s infinite linear;
  -moz-animation: spin 0.6s infinite linear;
  -o-animation: spin 0.6s infinite linear;
  animation: spin 0.6s infinite linear;
  border-radius: 100%;

  border-left: 6px solid $spinner-background;
  border-right: 6px solid $spinner-background;
  border-bottom: 6px solid $spinner-background;
  border-top: 6px solid $spinner-color;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;

  .spinner-background {
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.8;
  }

  .spinner-content {
    position: absolute;
    left: calc(50% - 30px);
    top: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}
