@use "assets/styles/global" as *;

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.modal-background {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.2;
}

.modal-content {
  background-color: white;
  position: absolute;
  top: 20%;
  left: calc(50% - 200px);
  padding: 2rem;
  width: 400px;
  border-radius: 14px;
}
